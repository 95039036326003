
import { Component, Vue } from 'vue-property-decorator';
import draggable from 'vuedraggable';
import { IUserProfile, IAppCreate } from '@/interfaces';
import {
  readUsers,
  readOneApp,
  readLastId,
  readSelectedUsers,
  readApps,
} from '@/store/admin/getters';
import {
  dispatchGetUsers,
  dispatchCreateApp,
  dispatchUpdateApp,
  dispatchAssignUsers,
  dispatchGetSelectedUsers,
  dispatchGetApps,
} from '@/store/admin/actions';
import { dispatchGetMyApps } from '@/store/apps/actions';

@Component({
  components: {
    draggable,
  },
})
export default class CreateUser extends Vue {
  private rules = {
    required: (value) => value.trim().length > 0 || 'Required',
    max: (value) => value.trim().length <= 6 || 'Max 6 characters',
    min: (value) => value.trim().length >= 3 || 'Min 3 characters',
  };
  private titles = ['main', 'users'];

  private appId = 0;
  private app: IAppCreate = {
    abbr: '',
    name: '',
    description: '',
    consumer: 0,
  };
  private consumer = '';
  private selectedUsers: IUserProfile[] = [];
  private allUsers: IUserProfile[] = [];
  private tab = 0;
  private valid = false;

  private async beforeMount() {
    this.appId = Number(this.$router.currentRoute.params.id ?? 0);
    if (this.appId > 0) {
      const apps = readApps(this.$store);
      if (apps.length === 0) {
        await dispatchGetApps(this.$store);
      }

      this.app = readOneApp(this.$store)(this.appId)!;
      this.consumer = String(this.app.consumer);

      await dispatchGetSelectedUsers(this.$store, { appId: this.appId });
      this.selectedUsers = readSelectedUsers(this.$store);
    }

    await dispatchGetUsers(this.$store);
    this.allUsers = readUsers(this.$store).filter(
      (user) => this.selectedUsers.findIndex((el) => el.id === user.id) < 0,
    );

    this.onSort();
  }

  private cancel() {
    this.$router.back();
  }

  private async submit() {
    if ((this.$refs.form as any).validate()) {
      this.app.name = this.app.name.trim();
      this.app.abbr = this.app.abbr.trim();
      this.app.description = this.app.description?.trim();
      this.app.consumer = Number(this.consumer);

      if (this.appId > 0) {
        await dispatchUpdateApp(this.$store, { id: this.appId, app: this.app });
      } else {
        await dispatchCreateApp(this.$store, this.app);
      }

      const lastId = readLastId(this.$store);
      if (lastId > 0) {
        await dispatchAssignUsers(this.$store, {
          appId: lastId,
          users: this.selectedUsers,
        });
        await dispatchGetMyApps(this.$store);
        this.$router.back();
      }
    }
  }

  private onInputAbbr() {
    this.app.abbr = this.app.abbr.toUpperCase();
  }

  private onSort() {
    this.allUsers = this.allUsers.sort((a, b) =>
      a.full_name + a.email > b.full_name + b.email ? 1 : -1,
    );
    this.selectedUsers = this.selectedUsers.sort((a, b) =>
      a.full_name + a.email > b.full_name + b.email ? 1 : -1,
    );
  }
}
